export const MBS_REVIEWS_V2_ENABLED_DOMAINS = [
  'www.paristickets.com.louvre-museum',
  'www.desert-safari-dxb.com',
  'www.desert-safari-dxb.com.morning-desert-safari-dubai',
  'www.singapore-tickets.com.resorts-world-sentosa.universal-studios-tickets',
  'www.penapalacetickets.com',
  'www.hagia-sophia-tickets.com',
  'www.temple-of-zeus.com',
  'www.abudhabi-tickets.com.attractions.museums-in-abu-dhabi',
  'www.hop-on-hop-off-tickets.com.paris-bus-tours',
  'www.barcelona-tickets.com.transportation.airport-transfers',
] as const;

export const MBS_EXTENDED_REVIEWS_V2_ENABLED_DOMAINS = [
  'www.bangkok-river-cruise.com',
  'www.dubai-tickets.co.cruises-in-dubai',
  'www.dubai-tickets.co.dubai-dolphinarium',
  'www.paristickets.com.catacombs',
  'www.paristickets.com.disneyland-paris',
  'www.barcelona-tickets.com.park-guell',
  'www.abudhabi-tickets.com.yas-waterworld-abu-dhabi',
  'www.paristickets.com.arc-de-triomphe',
  'www.last-supper-milan.com',
  'www.budapest-tickets.com.hungarian-parliament',
  'www.barcelona-tickets.com.barcelona-aquarium-tickets',
  'www.singapore-tickets.com.singapore-flyer-tickets',
  'www.phuket-tickets.com.fantasea-show',
  'www.topkapipalace-tickets.com',
  'www.basilicacisterntickets.com',
  'www.abudhabi-tickets.com.warner-bros-world-abu-dhabi',
  'www.tickets-venice.com.venice-water-bus-tickets',
  'www.bucharest-tickets.com.therme-bucharest',
  'www.castelsantelmotickets.com',
  'www.budapest-baths.com.szechenyi-baths',
  'www.hop-on-hop-off-tickets.com.barcelona-bus-tours',
  'www.hop-on-hop-off-tickets.com.london-bus-tours',
  'www.dubai-tickets.co.skydive-dubai',
  'www.budapest-river-cruises.com',
  'www.seine-river-cruises.com',
] as const;
